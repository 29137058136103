import React from "react"
import { Container, Row, Col } from 'react-bootstrap';

import Layout from "../components/layout"
import SEO from "../components/seo"

import awardImg from '../images/5star-mmueller.jpg'
import dorisAwardImg from '../images/5star_doris.jpg'

const AwardsPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Get Started" />
    <h1 style={styles.header}>Awards</h1>
    <div style={styles.body}>
    <Container fluid={true}>
      <Row>
        <Col lg={10}>
          <h5 style={{...styles.italic, ...styles.primaryText}}><a href="/michael-mueller">The Coastal Advisory Group Members congratulate Michael Mueller &amp; Doris Candito for again being named FIVE STAR Wealth Managers.</a></h5>
          <p>
            2019 FIVE STAR Wealth Managers constitute an elite group, representing less than 4 percent of the wealth managers in the Cincinnati area<sup>1</sup>.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={{span: 3, offset: 3}} sm={6} style={{textAlign: 'center'}}>
          <img src={awardImg} width="200" />
        </Col>
        <Col lg={{span: 3}} sm={6} style={{textAlign: 'center'}}>
          <img src={dorisAwardImg} width="200" />
        </Col>
      </Row>
      <p style={{fontSize: '.75em'}}>
        <sup>1</sup>Five Star Professional conducts market-specific research throughout the U.S. and Canada to identify 
        service professionals who provide quality services to their clients. Now entering its eighth year, the Five Star 
        Wealth Manager program is the largest and most widely published wealth manager award program in North America, 
        covering more than 40 major markets. The list of Five Star Wealth Managers for each market is published in the 
        leading city and regional magazine and/or business publication and on fivestarprofessional.com. Five Star award 
        winners do not pay a fee to be included in the research or the final list of award winners.
      </p>
    </Container>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default AwardsPage
